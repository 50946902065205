import styled from 'styled-components';

export const Wrapper = styled.div`
  .WiTRed {
    color: rgb(180, 2, 48);
    font-weight: bold;
  }
  .WiTRed:hover {
    color: pink;
  }
`;
